let user_id = document.getElementById("user-id").getAttribute("data-id");
let user_avatar = document.getElementById("user-id").getAttribute("data-avatar");
let user_name = document.getElementById("user-id").getAttribute("data-name");
// @if NODE_ENV!='production'
let token = '9944b09199c62bcf9418ad846dd0e4bbdfc6ee4b';
user_id = 30;
if (navigator.userAgent.search(/YaBrowser/) > 0) {
    token = '227ce67606e05b619a6660661dee41893b95e1c5';
    user_id = 41;
}

// @endif
const appUser = 'user_' + user_id;
const appName = 'wakeup';
const account = 'ashkov';
const URI = `${appUser}@${appName}.${account}.n4.voximplant.com`;
var currentCall = null,
    outboundCall = null;
var voxAPI = VoxImplant.getInstance();
voxAPI.init(
    {
        micRequired: true, // force microphone/camera access request
        videoSupport: true, // enable video support
        // showDebugInfo: true,
        // progressTone: true, // play progress tone
        localVideoContainerId: "stream-companion", // element id for local video from camera or screen sharing
        remoteVideoContainerId: "main-stream"
    }
);
const voxADM = VoxImplant.Hardware.AudioDeviceManager.get();
const voxVID = VoxImplant.Hardware.CameraManager.get();
var input_audio_devices = [];
var input_audio_index = 1;
voxADM.getInputDevices().then(function (devices) {
    for (d in devices) {
        if (devices[d].id != 'default') {
            input_audio_devices.push(devices[d].id)
        }
    }
});
var defaultAudioSettings = voxADM.getDefaultAudioSettings();
defaultAudioSettings['inputId'] = input_audio_devices[0];
voxADM.setDefaultAudioSettings(defaultAudioSettings);

function get_next_audio() {
    voxADM.setCallAudioSettings(currentCall, {inputId: input_audio_devices[input_audio_index], echoCancellation: true})
    input_audio_index = (input_audio_index + 1) % (input_audio_devices.length)
}

voxAPI.on(VoxImplant.Events.SDKReady, onSdkReady);
voxAPI.on(VoxImplant.Events.ConnectionEstablished, onConnectionEstablished);
voxAPI.on(VoxImplant.Events.ConnectionFailed, onConnectionFailed);
voxAPI.on(VoxImplant.Events.ConnectionClosed, onConnectionClosed);
voxAPI.on(VoxImplant.Events.IncomingCall, onIncomingCall);
// Listen to the server response
voxAPI.addEventListener(VoxImplant.Events.AuthResult, e => {
        if (e.result) {
            console.log(`AuthResult: ${e.result}`);
            console.log(`Auth code: ${e.code}`);
            // showLocalVideo(true);
            // Login is successful
        } else if (e.code == 302) {
            console.log(e.key);
            $.ajax({
                url: site_host_in_url + '/voximp/ott/' + e.key + '/',
                type: 'GET',
// @if NODE_ENV!='production'
                headers: {'Authorization': 'Token ' + token},
// @endif
                dataType: 'JSON',
                success: function (data) {
                    console.log(data);
                    voxAPI.loginWithOneTimeKey(URI, data['token']).then(function (result) {
                        console.log(result);
                    }).catch(function (error) {
                        console.error(error)
                    });
                }
            });
        }

    }
);

function onSdkReady() {
    log("onSDKReady version " + VoxImplant.version);
    log("WebRTC supported: " + voxAPI.isRTCsupported());
    connect();
}

function connect() {
    log("Establishing connection...");
    voxAPI.connect();
}

function onConnectionEstablished() {
    log("Connection established: " + voxAPI.connected());
    console.log(URI);
    voxAPI.requestOneTimeLoginKey(URI);
}

function log(text) {
    console.log(text);
}

function onConnectionFailed() {
    log("Connection failed");
    setTimeout(function () {
        voxAPI.connect();
    }, 1000);
}

function onConnectionClosed() {
    log("Connection closed");
    setTimeout(function () {
        voxAPI.connect();
    }, 1000);
}

function onIncomingCall(e) {
    on_calling();
    currentCall = e.call;

    console.log(e);
    // Add handlers
    currentCall.on(VoxImplant.CallEvents.Connected, onCallConnected);
    currentCall.on(VoxImplant.CallEvents.Disconnected, onCallDisconnected);
    currentCall.on(VoxImplant.CallEvents.Failed, onCallFailed);
    currentCall.on(VoxImplant.CallEvents.MediaElementCreated, onMediaElement);
    currentCall.on(VoxImplant.CallEvents.LocalVideoStreamAdded, onLocalVideoStream);
    log("Incoming call from: " + currentCall.number());
    // Answer automatically
    let th = "видео";
    let msg = JSON.parse(e.headers['VI-CallData']);
    if (msg.through == "audio") th = "audio"
    VideoCall.answer_window(msg).then(function (d) {
        // $("#videocall").show();
        // $(".client-card").removeClass('active');
        // $(".client-card[data-id=" + msg.frm_id + "]").addClass('active');

        Fancybox.close(false);

        VideoCall.streamBlockShow();

        sound.pause();

        let vs = $(".video-status");
        if (currentCall.settings.video) {
            vs.removeClass("off");
        } else {
            vs.addClass("off");
        }

        currentCall.answer(null, {}, {
            receiveVideo: true,
            sendVideo: currentCall.settings.video
        });
        // msg.to_id = msg.frm_id;
        //vc = new VideoCall(msg, false);
    }).catch(function (d) {
        VideoCall.on_disconnected();

        let out_msg = {
            'type': 'busy',
            'to_id': msg.frm_id,
            'uuid': currentCall.id()
        };
        console.log("busy from voximplant", out_msg);
        VideoCall.send_socket(out_msg);
    });
}

function createCall(to_id, video = true) {
    // $.ajax({
    //         type: "POST",
    //         url: '/api/vox_create_user/',
    //         headers: {'X-CSRFToken': $("[name='csrfmiddlewaretoken']").val()},
    //         data: {'user_id': to_id}
    //     }
    // ).done(function (data) {
    //     console.log(data);
    voxAPI.showLocalVideo(video).then(function () {
        on_calling();
        let through = video ? "video" : "audio";
        let msg = {
            'type': 'vox_call',
            'action': 'create',
            'to_id': to_id,
            'frm_id': user_id,
            'through': through,
            "frm_avatar": user_avatar,
            "frm_name": user_name
        }
        console.log('video', video, 'msg', msg);
        outboundCall = currentCall = voxAPI.call({
            number: "user_" + to_id,
            video: {receiveVideo: video, sendVideo: video},
            // video: video,
            customData: JSON.stringify(msg)
        });
        let vs = $(".video-status");
        if (video) {
            vs.removeClass("off");
        } else {
            vs.addClass("off");
        }
        msg.uuid = currentCall.id();
        // VideoCall.send_socket(msg);
        currentCall.on(VoxImplant.CallEvents.Connected, onCallConnected);
        currentCall.on(VoxImplant.CallEvents.Disconnected, onCallDisconnected);
        currentCall.on(VoxImplant.CallEvents.Failed, onCallFailed);
        currentCall.on(VoxImplant.CallEvents.MediaElementCreated, onMediaElement);
        currentCall.on(VoxImplant.CallEvents.LocalVideoStreamAdded, onLocalVideoStream);
    }).catch();
    // }).fail(function (error) {
    //     alert("Невозможно найти пользователя!");
    //     console.error(error);
    //     Fancybox.close(false);
    // });
}

function onCallConnected(e) {
    sound.pause();
    console.log('oncallconnected', e);
    log("CallConnected: " + currentCall.id());
    //        currentCall.hangup();
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(
        function handleError(error) {
            console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
        }
    );
    VideoCall.streamBlockShow();
    Fancybox.close(false);
    let sendVideo = false;
    if (typeof currentCall.settings.video == "boolean") {
        sendVideo = currentCall.settings.video
    } else {
        sendVideo = currentCall.settings.video.sendVideo;
    }
    console.log('current settings video & sendVideo', currentCall.settings.video, currentCall.settings.video.sendVideo);
    showLocalVideo(sendVideo);
}

// Show/hide local video
function showLocalVideo(flag) {
    console.log('show local video', flag);
    voxAPI.showLocalVideo(flag);
    if (currentCall) {
        if (flag == currentCall.settings.videoDirections.sendVideo) return;
        currentCall.sendVideo(flag).catch(function (error) {
            console.log('Во время изменения флага видео произошла ошибка: ', error)
        });
    }
}


function onCallDisconnected(e) {
    showLocalVideo(false);
    log("CallDisconnected: " + currentCall.id() + " Call state: " + currentCall.state());
    // currentCall = null;
    $(".stream-companion").removeClass("calling");
    VideoCall.justStreamBlockHide();
    sound.pause();
}

function onCallFailed(e) {
    showLocalVideo(false);
    log("CallFailed: " + currentCall.id() + " code: " + e.code + " reason: " + e.reason);
    if (e.code == 480) {
        alert(`Пользователя нет в сети!`);
    }
    VideoCall.streamCallHide();
}

function onMediaElement(e) {
    // For WebRTC just using JS/CSS for transformation
    // $video = $(e.element);
    // $video.appendTo('#voximplant_container');
    // $video.css('margin-left', '10px').css('width', '320px').css('height', '240px').css('float', 'left');
    // $video[0].play();
}

// Video stream from local screen sharing
function onLocalVideoStream(e) {
    console.log("LOCAL VIDEO STREAM");
    console.log(e);
    // if (e.type == "sharing") {
    //     $('#shareButton').html('Stop Sharing');
    //     $('#shareButton').off('click').click(function() {
    //         currentCall.stopSharingScreen();
    //         $('#shareButton').html('Share Screen');
    //         $('#shareButton').off('click').click(function() {
    //             currentCall.shareScreen(true);
    //         });
    //     });
    // }
}

function on_calling() {
    let sound_file = "/* @echo STATIC_ROOT */img/incoming-call.mp3";
    // if (this.is_outgoing) sound_file = "/* @echo STATIC_ROOT */img/outgoing-call.mp3";
    sound.src = sound_file;
    sound.loop = true;
    sound.play();
    sound.volume = 1;
    $(".stream-companion").addClass("calling");
    // $("#callButtons").show();
}

function create_user(uid) {
    return Promise((resolve, reject) => {
    });
}

// TODO после отмены соединения не отключается камера